import {useRef} from "react";
import styled from "styled-components";
import {
  Backdrop, H3, HighlightedText, Paragraph,
  SectionBackgroundBottom,
  SectionBackgroundTop,
  SectionContainer,
  TextWithBackground
} from "../../shared/shared";
import useFadeOutOnScroll from "../../../Hooks/useFadeOutOnScroll";
import {PopupButton} from "@typeform/embed-react";
import useShowHideAnimation from "../../../Hooks/useShowHideAnimation";
import arrow from "../../../assets/icons/arrow-icon.svg";
import {NAVS} from "../../../utils/Navs";

export const EOISection = () => {
  const sectionRef = useRef(null!)
  const opacity = useFadeOutOnScroll(sectionRef.current)
  const textRef = useRef(null!)
  const textProps = useShowHideAnimation(textRef.current)

  return (
    <Container id={NAVS[2].link} ref={sectionRef}>
      <Backdrop style={{opacity}}/>
      <SectionBackgroundTop/>
        <TextContainer ref={textRef} style={textProps}>
          <H3><HighlightedText>Interested</HighlightedText> in getting a Shiba?</H3>
          <Paragraph>Here at Kunogi, our goal is to provide you with the Shiba you truly <HighlightedText>desire</HighlightedText>,
            and we also commit to find a <HighlightedText>forever</HighlightedText> home for our Shiba.</Paragraph>
          <EnquireButton id="whC9rtyY">Enquire now</EnquireButton>
        </TextContainer>
      <SectionBackgroundBottom/>
    </Container>
    )
}

const EOIImageUrl = `${process.env.REACT_APP_MEDIA_URL}images/eoi.jpg`

const Container = styled(SectionContainer)`
  background-image: url(${EOIImageUrl});
  
  @media (max-width: 768px) {
    background-position-x: 75%;
    background-position-y: 80%;
  }
`

const TextContainer = styled(TextWithBackground)`
  position: absolute;
  width: 15%;
  padding: 0px 20px;
  top: 27vmin;
  left: 20%;
  
  min-width: 180px;
  
  @media (max-width: 768px) {
    left: 10%;
    top: 22%;
  }
`

const EnquireButton = styled(PopupButton)`
  font-family: Avenir Next, sans-serif;
  background: none;
  outline: none;
  border: none;
  color: white;
  padding: 0;
  font-size: 2vmin;
  margin-bottom: 1rem;
  cursor: pointer;
  text-decoration: underline;
  
  :after {
    content: "";
    background: white no-repeat;
    color: white;
    display: inline-block;
    width: 3vmin;
    height: 1vmin;
    margin-left: 5px;
    mask-image: url(${arrow});
    mask-repeat: no-repeat;
    
    @media (max-width: 768px) {
      width: 1rem;
      height: 0.5rem;
    }
  }
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`