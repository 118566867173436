import {animated} from "@react-spring/web";
import styled from "styled-components";
import {NAVS} from "../../../utils/Navs";
import {Href, TextWithBackground} from "../../shared/shared";


export const Nav = () => {
  return (
    <Container>
      <NavContainer>
        <NavInnerContainer>
          {NAVS.map((nav) => <NavItem to={`#${nav.link}`} key={nav.name}>{nav.name}</NavItem>)}
        </NavInnerContainer>
      </NavContainer>
    </Container>
  )
}

const Container = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  
  //@media (max-width: 768px) {
  //  display: none;
  //  visibility: hidden;
  //}
`;

const NavContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NavInnerContainer = styled(animated.div)`
  display: flex;
  justify-content: space-between;
  width: 75%;
  max-width: 1440px;
  padding: 0 1vmin;
  
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    flex-direction: column;
    background: none;
    height: 50vh;
    backdrop-filter: none;
  }
`

const NavItem = styled(Href)`
  padding: 1vmin;
  font-size: 2vmin;
  color: white;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    display: block;
  }
`
