import styled from "styled-components";
import instagram from "../../assets/icons/Instagram_logo_2022.svg.webp";
import facebook from "../../assets/icons/Facebook_f_logo_(2021).svg.webp";

export const Footer = () => {
  return (
    <Container>
      <InnerContainer>
        <span>Find us on</span>
        <a href="https://www.instagram.com/kunogi_shiba_inu/?igshid=MmIzYWVlNDQ5Yg%3D%3D" target="_blank">
          <Image src={instagram} alt="instagram"/>
        </a>
        <a href="https://www.facebook.com/profile.php?id=100093264842797" target="_blank">
          <Image src={facebook} alt="instagram"/>
        </a>
      </InnerContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
  max-width: 1920px;
  margin: 0 auto;
  height: 100px;
`

const InnerContainer = styled.div`
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2rem;
  gap: 12px;
`

const Image = styled.img`
  width: 24px;
`