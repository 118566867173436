import {useEffect} from "react";
import {animated, useSpringValue, config} from "@react-spring/web";
import styled from "styled-components";
import ArrowDown from "../icons/ArrowDown";

export const ScrollDownHint = () => {
  const opacity = useSpringValue(0.3,{
    config: config.molasses,
  })
  const y = useSpringValue(0, {
    config: config.molasses,
  })

  const scroll = () => {
    window.scroll(0, window.innerHeight)
  }

  useEffect(() => {
    y.start(20, {
      loop: () => {
        return { reverse: true }
      },
    })
    opacity.start(1, {
      loop: () => {
        return { reverse: true }
      }
    })
  }, [])

  return (
    <AnimatedButton style={{y, opacity, x: "-50%"}} onClick={scroll}>
      <ArrowDown width={"100%"} height={"100%"}/>
    </AnimatedButton>
  )
}

const AnimatedButton = styled(animated.button)`
  position: absolute;
  bottom: 10%;
  border: none;
  outline: none;
  background: none;
  width: 6vmin;
  min-width: 38px;
  height: auto;
  padding: 0;
  
  left: 50%;
  z-index: 100;
`