import {AnimationActions, AnimationStoreType} from "./AnimationStoreTypes";

const reducer = (state: AnimationStoreType, action: any) => {
  switch (action.type) {
    case AnimationActions.SET_SCROLL_Y_PROGRESS:
      return {
        ...state,
        scrollYProgress: action.payload,
      }
    default:
      return state
  }
}

export default reducer