import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 254 137" {...props}>
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M253.307 10.55a9.806 9.806 0 0 0-2.88-6.956c-3.84-3.84-10.069-3.84-13.907 0l-109.663 109.66L17.197 3.595c-3.842-3.84-10.069-3.84-13.91 0-3.839 3.842-3.839 10.069 0 13.91l116.615 116.615c3.838 3.841 10.068 3.841 13.907 0L250.424 17.504a9.797 9.797 0 0 0 2.883-6.955Z"
    />
  </svg>
)
export default SvgComponent
