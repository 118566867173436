import {useContext, useEffect, useState} from "react";
import {useSpring} from "@react-spring/web";
import {AnimationContext} from "../Context/AnimationContext/AnimationContext";
import {shouldHideElementOnScrollDown, shouldShowElementOnScrollUp} from "../utils/Math";

export const SPRING_CONfIG = {mass: 1, tension: 200, friction: 40}
export const DELAY = 200

const useShowHideAnimation =  (element: HTMLElement,
                shouldShowCheck: ((element: HTMLElement) => boolean) = shouldShowElementOnScrollUp,
                showHideCheck: ((element: HTMLElement) => boolean) = shouldHideElementOnScrollDown) => {
  const [shouldShow, setShouldShow] = useState(false)
  const {scrollYProgress} = useContext(AnimationContext)

  const props =  useSpring({
    opacity: shouldShow ? 1 : 0,
    transform: `translateY(${shouldShow ? 0 : 40}px)`,
    delay: DELAY,
    config: SPRING_CONfIG
  })

  useEffect(() => {
    if (!shouldShow && element && shouldShowCheck(element)) {
      setShouldShow(true)
    }
    if (shouldShow && element && showHideCheck(element)) {
      setShouldShow(false)
    }
  }, [scrollYProgress])

  return props
}

export default useShowHideAnimation;