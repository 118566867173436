export type AnimationStoreType = {
  scrollYProgress: number;
}

export interface AnimationContextType extends AnimationStoreType {
  setScrollYProgress: (progress: number) => void;
}

export const AnimationActions = {
  SET_SCROLL_Y_PROGRESS: 'SET_SCROLL_Y_PROGRESS',
}