import styled from "styled-components";
import {
  Backdrop, H3, HighlightedText, Paragraph,
  SectionBackgroundBottom,
  SectionBackgroundTop,
  SectionContainer,
  TextWithBackground
} from "../../shared/shared";
import useFadeOutOnScroll from "../../../Hooks/useFadeOutOnScroll";
import {useRef} from "react";
import useShowHideAnimation from "../../../Hooks/useShowHideAnimation";
import {NAVS} from "../../../utils/Navs";

export const OurShibaSection = () => {
  const sectionRef = useRef(null!)
  const opacity = useFadeOutOnScroll(sectionRef.current)
  const textRef = useRef(null!)
  const textProps = useShowHideAnimation(textRef.current)

  return (
    <Container id={NAVS[1].link} ref={sectionRef}>
      <Backdrop style={{opacity}}/>
      <SectionBackgroundTop/>
      <TextContainer ref={textRef} style={textProps}>
        <H3>Kunogi <HighlightedText>Shiba</HighlightedText></H3>
        <Paragraph>Our ambition is to improve the <HighlightedText>quality</HighlightedText> of Shiba Inu in Oceania.</Paragraph>
        <Paragraph>By working with other professional breeders locally and overseas,
          we aim to create the best <HighlightedText>looking</HighlightedText> and <HighlightedText>healthy </HighlightedText>
          Shiba with excellent <HighlightedText>temperament</HighlightedText>.</Paragraph>
      </TextContainer>
      <SectionBackgroundBottom/>
    </Container>
  )
}

const OurShibaImageUrl = `${process.env.REACT_APP_MEDIA_URL}images/our_shiba.jpg`

const Container = styled(SectionContainer)`
  background-image: url(${OurShibaImageUrl});
  
  @media (max-width: 768px) {
    background-position-x: 0%;
  }
`

const TextContainer = styled(TextWithBackground)`
  position: absolute;
  width: 15%;
  padding: 0px 20px;
  top: 15vw;
  left: 35%;
  
  @media (max-width: 768px) {
    left: 5%;
    top: 22%;
    width: 20%;
    min-width: 150px;
  }
`