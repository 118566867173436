import {createContext, useReducer} from "react";
import {AnimationActions, AnimationContextType, AnimationStoreType} from "./AnimationStoreTypes";
import reducer from "./AnimationReducers";

const initialState: AnimationStoreType = {
  scrollYProgress: 0,
}

export const AnimationContext = createContext<AnimationContextType>({
  ...initialState,
  setScrollYProgress: () => {}
})

export const AnimationContextProvider = ({children}: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = {
    scrollYProgress: state.scrollYProgress,
    setScrollYProgress: (progress: number) => {
      dispatch({type: AnimationActions.SET_SCROLL_Y_PROGRESS, payload: progress})
    }
  }
  return (
    <AnimationContext.Provider value={value}>
      {children}
    </AnimationContext.Provider>
  )
}
