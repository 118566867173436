export type NavType = {
  name: string;
  link: string;
}

export const NAVS: NavType[] = [{
  name: 'the Breed',
  link: 'the-breed',
}, {
  name: 'our Shiba',
  link: 'our-shiba',
}, {
  name: 'expression of Interest',
  link: 'expression-of-interest',
}, {
  name: 'planned Litters',
  link: 'planned-litters',
}, {
  name: 'guardian Family',
  link: 'guardian-family',
}];