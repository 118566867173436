import {useContext, useEffect, useState} from "react";
import {AnimationContext} from "../Context/AnimationContext/AnimationContext";
import {shouldHideElementOnScrollDown, shouldShowElementOnScrollUp} from "../utils/Math";
export default (element: HTMLElement,
                defaultToShow: boolean = false,
                shouldShowCheck: ((element: HTMLElement) => boolean) = shouldShowElementOnScrollUp,
                showHideCheck: ((element: HTMLElement) => boolean) = shouldHideElementOnScrollDown) => {
  const [shouldShow, setShouldShow] = useState(defaultToShow)
  const {scrollYProgress} = useContext(AnimationContext)

  useEffect(() => {
    if (!shouldShow && element && shouldShowCheck(element)) {
      setShouldShow(true)
    }
    if (shouldShow && element && showHideCheck(element)) {
      setShouldShow(false)
    }
  }, [scrollYProgress])

  return shouldShow
}