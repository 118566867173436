import {useEffect, useState} from "react";
import {useSpring} from "@react-spring/web";

const useFadeOutOnScroll = (sectionRef: HTMLElement, baseOpacity: number = 0) => {
  const [scrollYProgress, setScrollYProgress] = useState(0)
  const backgroundOpacityProps = useSpring({opacity: Math.min(baseOpacity + scrollYProgress * (1 - baseOpacity), 1)})
  const scrollListener = () => {
    const height = window.innerHeight;
    if (sectionRef) {
      const {top, bottom} = sectionRef.getBoundingClientRect();
      if (top <= 0 && bottom >= 0) {
        setScrollYProgress(-top / height)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollListener)

    return () => {
      document.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener])

  return backgroundOpacityProps.opacity
}

export default useFadeOutOnScroll;