import styled from "styled-components";
import {animated} from "@react-spring/web";
import {HashLink} from "react-router-hash-link";

export const SectionBackgroundTop = styled.div`
  position: absolute;
  width: 100%;
  height: 30%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
`

export const SectionBackgroundBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
`

export const SectionContainer = styled.section`
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;

  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  //scroll-snap-align: start;
`

export const Backdrop = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
`

export const TextWithBackground = styled(animated.div)`
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  user-select: none;
`

export const HighlightedText = styled.span`
  color: #ffcc00;
`

export const H1 = styled(animated.h1)`
  font-weight: normal;
`
export const H2 = styled(animated.h2)`
  font-weight: normal;
`
export const H3 = styled(animated.h3)`
  font-weight: normal;
  font-size: 3vmin;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

export const Paragraph = styled(animated.p)`
  font-size: 2vmin;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

export const UL = styled.ul`
  padding-inline-start: 15px;
  font-size: 2vmin;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`
export const Href = styled(HashLink)`
  color: white;
  font-size: 1rem;
  text-shadow: rgba(0,0,0,1) 0 0 4px;
  text-decoration: none;
`