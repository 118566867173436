import styled from "styled-components";
import {
  Backdrop,
  H3,
  HighlightedText, Paragraph,
  SectionBackgroundBottom,
  SectionBackgroundTop, SectionContainer,
  TextWithBackground
} from "../../shared/shared";
import useFadeOutOnScroll from "../../../Hooks/useFadeOutOnScroll";
import {useRef} from "react";
import useShowHideAnimation from "../../../Hooks/useShowHideAnimation";
import {NAVS} from "../../../utils/Navs";

export const TheBreedSection = () => {
  const sectionRef = useRef(null!)
  const opacity = useFadeOutOnScroll(sectionRef.current)
  const textRef = useRef(null!)
  const textProps = useShowHideAnimation(textRef.current)

  return (
    <Container id={NAVS[0].link} ref={sectionRef}>
      <Backdrop style={{opacity}}/>
      <SectionBackgroundTop/>
      <SectionBackgroundBottom/>
      <TextContainer ref={textRef} style={textProps}>
        <H3><HighlightedText>Shiba Inu</HighlightedText> is...</H3>
        <Paragraph>Japan's feisty fashion model. With its <HighlightedText>fox-like</HighlightedText> looks,
          <HighlightedText> curly</HighlightedText> tail, and <HighlightedText>sassiness</HighlightedText>,
          it's the haute couture of dog breeds.</Paragraph>
        <Paragraph>Don't let its small size fool you, this <HighlightedText>independent</HighlightedText> and
          <HighlightedText> intelligent</HighlightedText> pup is ready to strut its stuff and guard your home with flair. </Paragraph>
      </TextContainer>
    </Container>
  )
}

const TheBreedImageUrl = `${process.env.REACT_APP_MEDIA_URL}images/the_breed.jpg`

const Container = styled(SectionContainer)`
  background-image: url(${TheBreedImageUrl});
  
  @media (max-width: 768px) {
    background-position-y: 10%;
  }
`

const TextContainer = styled(TextWithBackground)`
  position: absolute;
  width: 15%;
  padding: 0px 20px;
  top: 20%;
  left: 14%;
  min-width: 180px;
  
  @media (max-width: 768px) {
    width:30%;
    top: 22%;
    left: 10%;
  }
`