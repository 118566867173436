import {
  Backdrop,
  H3,
  HighlightedText, Paragraph,
  SectionBackgroundBottom,
  SectionBackgroundTop,
  SectionContainer,
  TextWithBackground, UL
} from "../../shared/shared";
import styled from "styled-components";
import useFadeOutOnScroll from "../../../Hooks/useFadeOutOnScroll";
import {useRef} from "react";
import useShowHideAnimation from "../../../Hooks/useShowHideAnimation";
import {NAVS} from "../../../utils/Navs";

export const GuardianFamilySection = () => {
  const sectionRef = useRef(null!)
  const opacity = useFadeOutOnScroll(sectionRef.current)
  const textRef = useRef(null!)
  const textProps = useShowHideAnimation(textRef.current)

  return (
    <Container id={NAVS[4].link} ref={sectionRef}>
      <Backdrop style={{opacity}}/>
      <SectionBackgroundTop/>
      <SectionBackgroundBottom/>
      <TextContainer ref={textRef} style={textProps}>
        <H3>Guardian <HighlightedText>family</HighlightedText></H3>
        <UL>
          <li>Want more than a pet?</li>
          <li>Want to demonstrate the pride of your dog in shows?</li>
          <li>Want to contribute to preserving and improving this wonderful breed?</li>
        </UL>
        <Paragraph>Let us know what you think, and we can discuss how we work together as a family.</Paragraph>
      </TextContainer>
    </Container>
  )
}

const ImageUrl = `${process.env.REACT_APP_MEDIA_URL}images/guardian_family.jpg`

const Container = styled(SectionContainer)`
  background-image: url(${ImageUrl});
`

const TextContainer = styled(TextWithBackground)`
  position: absolute;
  width: 15%;
  padding: 0px 20px;
  top: 20%;
  left: 63%;
  min-width: 250px;
  
  @media (max-width: 768px) {
    width:30%;
    top: 8%;
    left: 10%;
  }
`