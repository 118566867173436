import React, {useContext} from 'react';
import './App.css';
import Homepage from "./pages/Homepage";
import {useScroll} from "@react-spring/web";
import {AnimationContext} from "./Context/AnimationContext/AnimationContext";

function App() {
  const { setScrollYProgress} = useContext(AnimationContext)
  useScroll({
    onChange: ({value: {scrollYProgress}}) => {
      setScrollYProgress(scrollYProgress)
    }
  })

  return (
    <div>
      <Homepage />
    </div>
  );
}

export default App;
