import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {animated, useSpring} from "@react-spring/web";
import {isMobile} from "react-device-detect";
import {Nav} from "./Nav";
import useShowHide from "../../../Hooks/useShowHide";
import {shouldHideElementOnScrollUp, shouldShowElementOnScrollDown} from "../../../utils/Math";
import {Backdrop, SectionBackgroundBottom} from "../../shared/shared";
import useFadeOutOnScroll from "../../../Hooks/useFadeOutOnScroll";
import rounded_logo from "../../../assets/icons/rounded_logo.png";
import {ScrollDownHint} from "../ScrollDownHint";
import kanji from "../../../assets/icons/kanji.svg";

const VideoUrl = `${process.env.REACT_APP_MEDIA_URL}video/banner${isMobile ? '-mobile' : ''}.mp4`

const Banner = () => {
  const [flipped, setFlipped] = useState(false)
  const videoRef = useRef(null!)
  const sectionRef = useRef(null!)
  const backdropOpacity = useFadeOutOnScroll(sectionRef.current)
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  })

  const shouldShowVideo = useShowHide(videoRef.current,
    true,
    shouldShowElementOnScrollDown,
    shouldHideElementOnScrollUp);

  useEffect(() => {
    const interval = setInterval(() => {
      setFlipped(state => !state)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Container ref={sectionRef}>
      <Video muted autoPlay playsInline loop
             src={VideoUrl} style={{visibility: shouldShowVideo ? 'visible' : 'hidden'}}
             ref={videoRef}>
        <source src={VideoUrl} type="video/mp4"/>
      </Video>
      <Backdrop style={{opacity: backdropOpacity}}/>
      <LogoContainer>
        <TextContainer style={{ opacity: opacity.to((o) => 1 - o), transform }}>
          <LogoBigText>Kunogi</LogoBigText>
          <LogoSmallText>Kennel</LogoSmallText>
        </TextContainer>
        <TextContainer style={{
          opacity,
          transform,
          rotateX: "180deg"
        }}>
          <LogoKanji src={kanji} alt="kunogi" />
        </TextContainer>
        <LogoImage src={rounded_logo}/>
      </LogoContainer>
      <SectionBackgroundBottom/>
      <Nav />
      <ScrollDownHint/>
    </Container>
  );
};

const VideoCoverUrl = `${process.env.REACT_APP_MEDIA_URL}images/video_cover.jpg`

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: black;
  background-image: url(${VideoCoverUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  scroll-snap-align: start;
`

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: auto;
  height: 100vh;
  min-width: 100%;
  object-fit: cover;
`;

const LogoContainer = styled.div`
  position: relative;
  z-index: 1;
  padding-left: 16px;
  margin: 32px auto 0;
  color: white;
  width: 100%;
  max-width: 1920px;
`

const LogoImage = styled.img`
  opacity: 0.8;
  height: 80px;                              
  width: 80px;
  position: absolute;
  top: 0;
  left: 118px;
`
const TextContainer = styled(animated.div)`
  position: absolute;
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
  will-change: transform, opacity;
`

const LogoBigText = styled.div`
  font-size: 1.7em;
  padding-top: 20px;
`

const LogoSmallText = styled.div`
  font-size: 1.2em;
  padding-left: 34px;
  margin-top: -5px;
`

const LogoKanji = styled.img`
  width: 4em;
  transform: translate3d(54px, 23px, 0px);
`

export default Banner;
