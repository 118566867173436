import React from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate, render } from "react-dom";
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AnimationContextProvider} from "./Context/AnimationContext/AnimationContext";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   // <React.StrictMode>
//     <BrowserRouter>
//       <AnimationContextProvider>
//         <App />
//       </AnimationContextProvider>
//     </BrowserRouter>
//   // </React.StrictMode>
// );

const rootElement = document.getElementById("root");
const content =
// <React.StrictMode>
  <BrowserRouter>
    <AnimationContextProvider>
      <App />
    </AnimationContextProvider>
  </BrowserRouter>
// </React.StrictMode>

if (rootElement && rootElement.hasChildNodes()) {
  hydrate(content, rootElement);
} else {
  render(content, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
