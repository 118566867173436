import styled from "styled-components";
import {animated} from "@react-spring/web";
import {useRef} from "react";
import useShowHideAnimation from "../../../Hooks/useShowHideAnimation";
import {Backdrop, SectionBackgroundBottom, SectionBackgroundTop, SectionContainer} from "../../shared/shared";
import useFadeOutOnScroll from "../../../Hooks/useFadeOutOnScroll";

export const WelcomeSection = () => {
  const sectionRef = useRef(null!)
  const opacity = useFadeOutOnScroll(sectionRef.current)
  const welcome1Ref = useRef<HTMLInputElement>(null!)
  const welcome2Ref = useRef<HTMLInputElement>(null!)
  const welcome3Ref = useRef<HTMLInputElement>(null!)

  const welcome1Props = useShowHideAnimation(welcome1Ref.current)
  const welcome2Props = useShowHideAnimation(welcome2Ref.current)
  const welcome3Props = useShowHideAnimation(welcome3Ref.current)

  return (
    <Container id="welcome" ref={sectionRef}>
      <Backdrop style={{opacity}}/>
      <SectionBackgroundTop/>
      <WelcomeText ref={welcome1Ref} style={welcome1Props}>Welcome</WelcomeText>
      <WelcomeText2 ref={welcome2Ref} style={welcome2Props}>to</WelcomeText2>
      <WelcomeText3 ref={welcome3Ref} style={welcome3Props}>Kunogi Kennel</WelcomeText3>
      <SectionBackgroundBottom/>
    </Container>
  )
}

const WelcomeImageUrl = `${process.env.REACT_APP_MEDIA_URL}images/welcome.jpg`

const Container = styled(SectionContainer)`
  background-image: url(${WelcomeImageUrl});
`

const WelcomeText = styled(animated.h1)`
  font-family: Zapfino;
  user-select: none;
  z-index: 2;
  font-size: 10vmin;
  text-shadow: rgba(0, 0, 0, 0.3) 3px 3px;
  margin-top: 20px;
  margin-bottom: -5vw;
  font-weight: normal;
`

const WelcomeText2 = styled(animated.h2)`
  font-family: Zapfino;
  user-select: none;
  z-index: 2;
  font-size: 6vmin;
  text-shadow: rgba(0, 0, 0, 0.3) 3px 3px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
`

const WelcomeText3 = styled(animated.h1)`
  user-select: none;
  margin-top: 6vw;
  font-size: 14.5vmin;
  text-shadow: rgba(0, 0, 0, 0.6) 3px 3px 4px;
  font-weight: normal;
`