export const shouldShowElementOnScrollUp = (element: HTMLElement) => {
  const top = element.getBoundingClientRect().top
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
  return top < windowHeight
}

export const shouldShowElementOnScrollDown = (element: HTMLElement) => {
  const bottom = element.getBoundingClientRect().bottom
  return bottom > 0
}

export const shouldHideElementOnScrollDown = (element: HTMLElement) => {
  const top = element.getBoundingClientRect().top
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
  return top > windowHeight
}

export const shouldHideElementOnScrollUp = (element: HTMLElement) => {
  const bottom = element.getBoundingClientRect().bottom
  return bottom < 0
}