import {
  Backdrop,
  H3, HighlightedText, Paragraph,
  SectionBackgroundBottom,
  SectionBackgroundTop,
  SectionContainer,
  TextWithBackground
} from "../../shared/shared";
import styled from "styled-components";
import useFadeOutOnScroll from "../../../Hooks/useFadeOutOnScroll";
import {useRef} from "react";
import useShowHideAnimation from "../../../Hooks/useShowHideAnimation";
import {NAVS} from "../../../utils/Navs";

export const PlannedLittersSection = () => {
  const sectionRef = useRef(null!)
  const opacity = useFadeOutOnScroll(sectionRef.current)
  const textRef = useRef(null!)
  const textProps = useShowHideAnimation(textRef.current)

  return (
    <Container id={NAVS[3].link} ref={sectionRef}>
      <Backdrop style={{opacity}}/>
      <SectionBackgroundTop/>
      <SectionBackgroundBottom/>
      <TextContainer ref={textRef} style={textProps}>
        <H3>Future <HighlightedText>litters</HighlightedText></H3>
        <Paragraph>We will <HighlightedText>continuously</HighlightedText> update our planned litters here,
          including the <HighlightedText>parents'</HighlightedText> info and number of <HighlightedText>puppies</HighlightedText> expected.</Paragraph>
        <Paragraph>Watch this place!</Paragraph>
      </TextContainer>
    </Container>
  )
}

const ImageUrl = `${process.env.REACT_APP_MEDIA_URL}images/planned_litters.jpg`

const Container = styled(SectionContainer)`
  background-image: url(${ImageUrl});
  
  @media (max-width: 768px) {
    background-position-x: 30%;
  }
`

const TextContainer = styled(TextWithBackground)`
  position: absolute;
  width: 15%;
  padding: 0px 20px;
  top: 20%;
  left: 63%;
  min-width: 180px;
  
  @media (max-width: 768px) {
    width:30%;
    top: 15%;
    left: 25%;
  }
`