import {memo} from "react";
import Banner from "../components/Homepage/Banner/Banner";
import {WelcomeSection} from "../components/Homepage/Sections/WelcomeSection";
import {TheBreedSection} from "../components/Homepage/Sections/TheBreedSection";
import {OurShibaSection} from "../components/Homepage/Sections/OurShibaSection";
import {EOISection} from "../components/Homepage/Sections/EOISection";
import {VerticalNav} from "../components/VerticalNav";
import {PlannedLittersSection} from "../components/Homepage/Sections/PlannedLittersSection";
import {GuardianFamilySection} from "../components/Homepage/Sections/GuardianFamilySection";
import {Footer} from "../components/Homepage/Footer";

const Homepage = memo(() => {
  return (
    <div>
      <Banner />
      <WelcomeSection/>
      <TheBreedSection/>
      <OurShibaSection/>
      <EOISection/>
      <PlannedLittersSection/>
      <GuardianFamilySection/>
      <Footer/>
      {/*<VerticalNav/>*/}
    </div>
  );
});

export default Homepage;